import { createApi } from "@reduxjs/toolkit/query/react";
import { prepareBaseQuery } from "src/shared/config/storeConfig";
import {
  RegisterFormParams,
  UpdateUserAccountPayloads,
} from "src/shared/models/containers/account.model";

export const privateApi = createApi({
  reducerPath: "privateApi",
  tagTypes: [
    "Account",
    "Requests",
    "PaymentMethods",
    "Balance",
    "AutoPay",
    "Reporting",
    "WoCategories",
  ],
  baseQuery: prepareBaseQuery(false),
  endpoints: (builder) => ({
    resendEmail: builder.mutation({
      query: () => ({
        url: `v1/users/send-verify-email`,
        method: "POST",
      }),
    }),
    resetPasswordEmail: builder.mutation({
      query: () => ({
        url: `v1/users/reset-password-email`,
        method: "POST",
      }),
    }),
    getUser: builder.query({
      query: () => ({
        url: `/v1/users/`,
        method: "GET",
      }),
      providesTags: ["Reporting", "AutoPay", "PaymentMethods", "Account"],
    }),
    createUser: builder.mutation({
      query: (userDataBody: RegisterFormParams) => ({
        url: `/v1/users/`,
        method: "POST",
        body: userDataBody,
      }),
    }),
    updateUserAccount: builder.mutation({
      query: (userDataBody: UpdateUserAccountPayloads) => ({
        url: `/v1/users/update-account`,
        method: "POST",
        body: userDataBody,
      }),
      invalidatesTags: ["Reporting", "AutoPay", "PaymentMethods", "Account"],
    }),
    getWorkOrderCategories: builder.mutation({
      query: () => ({
        url: `/v2/work-orders/categories`,
        method: "GET",
      }),
    }),
    getWorkOrderCategoriesQ: builder.query({
      query: () => ({
        url: `/v2/work-orders/categories`,
        method: "GET",
      }),
      providesTags: ["WoCategories"],
    }),
    createWorkOrder: builder.mutation({
      query: (body) => ({
        url: `/v1/work-orders`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Requests"],
    }),
    getWorkOrders: builder.query({
      query: (obj: any) => {
        if (obj.isDateFilterAvailable) {
          return {
            url: `/v1/work-orders`,
            method: "GET",
            params: obj.params,
          };
        }
        return {
          url: `/v1/work-orders`,
          method: "GET",
        };
      },
      providesTags: ["Requests"],
    }),
    getWorkOrderById: builder.query({
      query: (id: string) => {
        return {
          url: `/v1/work-orders/by-id/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Requests"],
    }),
    getPaymentMethods: builder.query({
      query: () => {
        return {
          url: `/v1/payments/methods`,
          method: "GET",
        };
      },
      providesTags: ["PaymentMethods"],
    }),
    addBankAccount: builder.mutation({
      query: (body) => ({
        url: `/v1/payments/methods/add-bank`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["PaymentMethods"],
    }),
    updateDefaultBankAccount: builder.mutation({
      query: (state) => ({
        url: `/v1/payments/methods/${state.id}/set-default`,
        method: "PATCH",
      }),
      invalidatesTags: ["PaymentMethods"],
    }),
    deleteBankAccount: builder.mutation({
      query: (state) => ({
        url: `/v1/payments/methods/${state.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PaymentMethods"],
    }),
    getBalanceDetails: builder.query({
      query: () => {
        return {
          url: `/v1/users/account-balance`,
          method: "GET",
        };
      },
      providesTags: ["Balance"],
    }),
    getTransactions: builder.query({
      query: (obj: any) => {
        if (obj.endDate) {
          return {
            url: `/v1/users/transaction-history`,
            method: "GET",
            params: {
              dateFrom: obj.startDate,
              dateTo: obj.endDate,
            },
          };
        }
        return {
          url: `/v1/users/transaction-history`,
          method: "GET",
          params: {
            dateFrom: obj.params.startDate,
          },
        };
      },
      providesTags: ["PaymentMethods"],
    }),
    addPaymentCard: builder.mutation({
      query: (body) => ({
        url: `/v1/payments/methods/add-card`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["PaymentMethods"],
    }),
    makePayment: builder.mutation({
      query: (body) => ({
        url: `/v1/payments/make-payment`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Balance"],
    }),
    getFeeStructure: builder.mutation({
      query: (amount) => ({
        url: `/v1/payments/fee-structure/${amount}`,
        method: "GET",
      }),
      invalidatesTags: ["Balance"],
    }),
    getAutoPay: builder.query({
      query: () => ({
        url: `/v1/autopay`,
        method: "GET",
      }),
      providesTags: ["AutoPay"],
    }),
    addAutoPay: builder.mutation({
      query: () => ({
        url: `/v1/autopay`,
        method: "PUT",
      }),
      invalidatesTags: ["AutoPay"],
    }),
    updateAutoPay: builder.mutation({
      query: (body) => ({
        url: `/v1/autopay/update`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["AutoPay"],
    }),
    reportCreditSubscribe: builder.mutation({
      query: (body) => ({
        url: `/v1/credit-reporting/`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Reporting"],
    }),
    reportCreditUnsubscribe: builder.mutation({
      query: () => ({
        url: `/v1/credit-reporting/`,
        method: "PUT",
      }),
      invalidatesTags: ["Reporting"],
    }),
    getReportingStatements: builder.query({
      query: (obj: any) => ({
        url: `/v1/credit-reporting/statement`,
        method: "GET",
        params: {
          startDate: obj.startDate,
          endDate: obj.endDate,
        },
      }),
    }),
    getCreditScore: builder.query({
      query: () => ({
        url: `/v1/credit-reporting/score`,
        method: "GET",
      }),
    }),
    postLeaseApplication: builder.mutation({
      query: (body) => ({
        url: `/v1/lease/application`,
        method: "POST",
        body: body,
      }),
    }),
    postCardPayment: builder.mutation({
      query: (body) => ({
        url: `/v1/payments/card-payment`,
        method: "POST",
        body: body,
      }),
    }),
    postAchPayment: builder.mutation({
      query: (body) => ({
        url: `/v1/payments/ach-payment`,
        method: "POST",
        body: body,
      }),
    }),
    postTicket: builder.mutation({
      query: (body) => ({
        url: `/v1/users/ticket`,
        method: "POST",
        body: body,
      }),
    }),
    getDocs: builder.query({
      query: () => ({
        url: `/v1/lease/docs`,
        method: "GET",
      }),
    }),
    getDoc: builder.query({
      query: (id: string) => ({
        url: `/v1/lease/doc/${id}`,
        method: "GET",
      }),
    }),
    getLeaseBalance: builder.query({
      query: (id: string) => ({
        url: `/v1/lease/${id}/balance`,
        method: "GET",
      }),
    }),
    getPaymentTransactions: builder.query({
      query: () => ({
        url: `/v1/payments/transactions`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useResendEmailMutation,
  useResetPasswordEmailMutation,
  useGetUserQuery,
  useLazyGetUserQuery,
  useCreateUserMutation,
  useUpdateUserAccountMutation,
  useGetWorkOrderCategoriesMutation,
  useCreateWorkOrderMutation,
  useGetWorkOrdersQuery,
  useGetWorkOrderByIdQuery,
  useGetPaymentMethodsQuery,
  useAddBankAccountMutation,
  useUpdateDefaultBankAccountMutation,
  useDeleteBankAccountMutation,
  useGetBalanceDetailsQuery,
  useGetTransactionsQuery,
  useAddPaymentCardMutation,
  useMakePaymentMutation,
  useGetFeeStructureMutation,
  useGetAutoPayQuery,
  useAddAutoPayMutation,
  useUpdateAutoPayMutation,
  useReportCreditSubscribeMutation,
  useGetReportingStatementsQuery,
  useGetCreditScoreQuery,
  usePostLeaseApplicationMutation,
  useGetLeaseBalanceQuery,
  usePostCardPaymentMutation,
  usePostAchPaymentMutation,
  usePostTicketMutation,
  useReportCreditUnsubscribeMutation,
  useGetDocsQuery,
  useLazyGetDocQuery,
  useLazyGetPaymentTransactionsQuery,
  useGetWorkOrderCategoriesQQuery,
} = privateApi;
