import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";

import {
  FormAuthorizedWrapper,
  ShadowCard,
  ButtonAuthorizedWrapper,
  FromAutoCompleteSelect,
  FormTextArea,
  FormInput,
  ManualLoader,
  Messages,
  ConfirmationModal,
} from "src/components";

import {
  CreateOrderBodyParams,
  MaintainanceRequestCreateParams,
} from "src/shared/models/containers/request.model";

import { ERROR_MESSAGES } from "src/shared/utilities/validationMessages";
import { updateBindingsChange } from "src/shared/utilities/formUtilities";
import {
  dateFormatYYYYspaceMMspaceDDspaceTime,
  dateFormatYYYYspaceMMspaceDDspaceTimeAddMoreDays,
} from "src/shared/utilities/dateConvertions";

import {
  useCreateWorkOrderMutation,
  useGetWorkOrderCategoriesMutation,
  useGetWorkOrderCategoriesQQuery,
} from "src/store/services/privateApi";

import { resetTheCreateRequestFormFields } from "src/helpers/helperRequests";
import { areasListData } from "src/assets/workOrdersData";

import { MaintenanceRequestFormProps } from "src/shared/models/containers/request.model";
import { FormTextAreaLabel } from "../../../../components/FormElements/FormTextArea/FormTextArea.style";

export const MaintenanceRequestForm = ({
  t,
  navigate,
  isUpdateRequest,
  requestData,
  onSubmitSupportEvent,
}: MaintenanceRequestFormProps) => {
  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const [categoriesList, setCategoriesList] = useState<any>();
  const [issueTypeList, setIssueTypeList] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const { data: categories, isLoading: isLoadingGetCategories } =
    useGetWorkOrderCategoriesQQuery({});

  const [
    createWorkOrderMutation,
    { isLoading: isLoadingCreateOrder, data: createOrderResponseData },
  ] = useCreateWorkOrderMutation();

  /**
   * getCategoryId for on CHange of category autocomplete component
   */
  const getSubCategoriesForOnChange = (selectedCategory: string = "") => {
    if (selectedCategory !== "" && categories?.length > 0) {
      const selectedCategoryNode = categories?.filter(
        (node: { name: string }) => node.name === selectedCategory
      );

      const selectedSubCategories = selectedCategoryNode[0].subCategories ?? "";

      if (selectedSubCategories?.length > 0) {
        const responseForIssueTypeDropDown = selectedSubCategories.map(
          (node: any) => ({
            label: node.name,
            id: node.id,
          })
        );
        setIssueTypeList(responseForIssueTypeDropDown);
        return responseForIssueTypeDropDown;
      }
    }
  };

  /**
   * Handle in the initial load
   */
  useEffect(() => {
    if (categories) {
      const responseForCategoryDropDown = categories?.map(
        (node: { name: string }) => ({
          label: node.name,
        })
      );
      setCategoriesList(responseForCategoryDropDown);
    }
    if (isUpdateRequest) {
      if (requestData) {
        setValue("areas", requestData?.Areas ? requestData?.Areas[0] : "");
        setValue("description", requestData?.description ?? "");
        setValue("status", requestData?.Status);
        setValue("subject", requestData.title);
        setValue("categoryId", requestData?.Category);
        const issueType = getSubCategoriesForOnChange(
          requestData?.Category
        )?.find((a: any) => a.id === requestData?.CategoryID);
        setValue("title", issueType?.label);
        if (requestData?.Appointment === "Permission Given") {
          setIsConsentChecked(true);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateRequest, requestData, categories]);

  /**
   * define the validation schema for form validation
   */
  const maintainanceRequestCreateFormSchema = yup
    .object({
      areas: yup
        .string()
        .typeError(ERROR_MESSAGES.REQUIRED)
        .required(ERROR_MESSAGES.REQUIRED),
      subject: yup
        .string()
        .typeError(ERROR_MESSAGES.REQUIRED)
        .required(ERROR_MESSAGES.REQUIRED)
        .max(150, ERROR_MESSAGES.TITLE_MAX_LIMIT),
      title: yup
        .string()
        .typeError(ERROR_MESSAGES.REQUIRED)
        .required(ERROR_MESSAGES.REQUIRED),
      categoryId: yup
        .string()
        .typeError(ERROR_MESSAGES.REQUIRED)
        .required(ERROR_MESSAGES.REQUIRED),
      description: yup
        .string()
        .typeError(ERROR_MESSAGES.REQUIRED)
        .required(ERROR_MESSAGES.REQUIRED)
        .max(4000, ERROR_MESSAGES.DESCRIPTION_MAX_LIMIT),
      status: yup.string().when("front", {
        is: () => {
          if (!isUpdateRequest) {
            return false;
          } else {
            return true;
          }
        },
        then: yup
          .string()
          .typeError(ERROR_MESSAGES.REQUIRED)
          .required(ERROR_MESSAGES.REQUIRED),
      }),
    })
    .required();

  /**
   * initialize the hook form
   */
  const {
    register,
    handleSubmit,
    setValue,
    // getValues,
    control,
    trigger,
    formState: { errors },
  } = useForm<MaintainanceRequestCreateParams>({
    resolver: yupResolver(maintainanceRequestCreateFormSchema),
  });

  /**
   * handle the create request form submition
   */
  const formSubmit = handleSubmit(async (data) => {
    const currentDate = dateFormatYYYYspaceMMspaceDDspaceTime(new Date());
    const setDueDate = dateFormatYYYYspaceMMspaceDDspaceTimeAddMoreDays(
      new Date(),
      4
    );

    let setCreateOrderBody: CreateOrderBodyParams = {
      reportedDate:
        isUpdateRequest && requestData ? requestData.ReportedDate : currentDate,
      title: `${data.subject}`,
      categoryId: issueTypeList.find((a: any) => a.label === data.title)?.id,
      areas: `${data.areas}`,
      description: `${data.description}`,
      priority: isUpdateRequest && requestData ? requestData.Priority : "High",
      dueDate:
        isUpdateRequest && requestData ? requestData.DueDate : setDueDate,
      appointment: isConsentChecked ? "Permission Given" : "Call",
    };

    if (isUpdateRequest && requestData) {
      setCreateOrderBody = {
        ...setCreateOrderBody,
        phone: requestData.Phone ?? "",
        status: data.status,
        workorderId: requestData?.WorkOrderID,
      };
    }

    const response: any = await createWorkOrderMutation(setCreateOrderBody);

    if (response?.data) {
      setIsConsentChecked(false);
      setConfirmationModalOpen(true);
      setIssueTypeList(null);
      resetTheCreateRequestFormFields(setValue);
    } else {
      setErrorMessage(
        response?.error?.data?.error ??
          response?.error?.data?.message ??
          t("requests.addOrUpdateRequest.enterValidDetailMessage")
      );
    }
  });

  /**
   * Update onchange consent
   */
  const onChangeConsent = (event: any) => {
    const isConsentFieldChecked = event.target.checked;
    if (isConsentFieldChecked) {
      setIsConsentChecked(true);
    } else {
      setIsConsentChecked(false);
    }
  };

  /**
   * Click event for confirmation modal submit button
   */
  const onSubmitConfirmationModalBtn = () => {
    if (isUpdateRequest) {
      onSubmitSupportEvent && onSubmitSupportEvent();
    } else {
      navigate("/requests");
    }
    setConfirmationModalOpen(false);
  };

  return (
    <>
      {(isLoadingGetCategories || isLoadingCreateOrder) && <ManualLoader />}
      <FormAuthorizedWrapper onSubmit={formSubmit}>
        <ShadowCard padding={4} marginBottom={6}>
          <Controller
            control={control}
            name="areas"
            render={({ field: { onChange, value } }) => {
              return (
                <FromAutoCompleteSelect
                  label={t("formInput.areas")}
                  id={"areas"}
                  {...register("areas")}
                  placeholder={t("placeHolder.areas")}
                  error={errors?.areas?.message}
                  inputValue={value || ""}
                  onChange={(_event: any, selectedOption: any) => {
                    if (selectedOption) {
                      onChange(selectedOption.label);
                    }
                  }}
                  options={areasListData}
                  type="shadowed"
                />
              );
            }}
          />
          {categoriesList && categoriesList.length > 0 && (
            <Controller
              control={control}
              name="categoryId"
              render={({ field: { onChange, value } }) => {
                return (
                  <FromAutoCompleteSelect
                    label={t("formInput.category")}
                    id={"categoryId"}
                    {...register("categoryId")}
                    placeholder={t("placeHolder.category")}
                    error={errors?.categoryId?.message}
                    inputValue={value || ""}
                    onChange={(_event: any, selectedOption: any) => {
                      if (selectedOption) {
                        onChange(selectedOption.label);
                        getSubCategoriesForOnChange(selectedOption.label);
                      }
                    }}
                    options={categoriesList}
                    type="shadowed"
                  />
                );
              }}
            />
          )}

          {issueTypeList && (
            <Controller
              control={control}
              name="title"
              render={({ field: { onChange, value } }) => {
                return (
                  <>
                    {issueTypeList.length > 0 && (
                      <FromAutoCompleteSelect
                        label={t("formInput.issueType")}
                        id={"title"}
                        {...register("title")}
                        placeholder={t("placeHolder.issueType")}
                        error={errors?.title?.message}
                        inputValue={value || ""}
                        onChange={(_event: any, selectedOption: any) => {
                          onChange(selectedOption.label);
                        }}
                        inputFocus={(e: any) => {
                          trigger("title");
                        }}
                        options={issueTypeList}
                        type="shadowed"
                      />
                    )}
                  </>
                );
              }}
            />
          )}

          {isUpdateRequest && (
            <Controller
              control={control}
              name="status"
              render={({ field: { onChange, value } }) => {
                return (
                  <FromAutoCompleteSelect
                    label={"Status"}
                    id={"status"}
                    {...register("status")}
                    placeholder={t("placeHolder.status")}
                    error={errors?.status?.message}
                    inputValue={value || ""}
                    onChange={(_event: any, selectedOption: any) => {
                      if (selectedOption) {
                        onChange(selectedOption.label);
                      }
                    }}
                    options={[{ label: "Cancelled" }, { label: "Submitted" }]}
                    type="shadowed"
                  />
                );
              }}
            />
          )}
          <FormTextAreaLabel className={"default-label"}>
            Title
          </FormTextAreaLabel>
          <Box height={"10px"}></Box>
          <Controller
            control={control}
            name="subject"
            render={({ field: { value } }) => {
              return (
                <FormInput
                  label=""
                  id={"subject"}
                  {...register("subject")}
                  placeholder={""}
                  error={errors?.description?.message}
                  onChange={(event: any) => {
                    updateBindingsChange(event, "subject", setValue);
                  }}
                  inputFocus={(e: any) => {
                    trigger("subject");
                  }}
                  value={value}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="description"
            render={({ field: { value } }) => {
              return (
                <FormTextArea
                  label={t("formInput.description")}
                  id={"description"}
                  {...register("description")}
                  placeholder={t("placeHolder.description")}
                  error={errors?.description?.message}
                  onChange={(event: any) => {
                    updateBindingsChange(event, "description", setValue);
                  }}
                  inputFocus={(e: any) => {
                    trigger("description");
                  }}
                  value={value}
                />
              );
            }}
          />

          <FormControlLabel
            sx={{ marginBottom: 1 }}
            control={
              <Checkbox
                name="confirm_subscription"
                size="small"
                onChange={onChangeConsent}
                checked={isConsentChecked}
              />
            }
            label={
              <Typography variant="h2" color={"gray.700"}>
                {t("formInput.consentCheck")}
              </Typography>
            }
          />
          {errorMessage !== "" && (
            <Messages
              messageHeading={
                !isUpdateRequest
                  ? t("requests.addOrUpdateRequest.newRequestTitle")
                  : t("requests.addOrUpdateRequest.updateRequestTitle")
              }
              messageContent={errorMessage}
              closeEvent={() => setErrorMessage("")}
              bottomMargin={0}
              topMargin={4}
            />
          )}
        </ShadowCard>

        <ButtonAuthorizedWrapper>
          <Button
            variant="containedLarge"
            color="primary"
            fullWidth
            type="submit"
            sx={{ overflow: "hidden" }}
          >
            {!isUpdateRequest && t("buttonTexts.submitButton")}
            {isUpdateRequest && t("buttonTexts.updateButton")}
          </Button>
        </ButtonAuthorizedWrapper>
      </FormAuthorizedWrapper>
      {confirmationModalOpen && (
        <ConfirmationModal
          onFormSubmit={onSubmitConfirmationModalBtn}
          title={t("requests.addOrUpdateRequest.confirmationModalTitle")}
          content={
            isUpdateRequest
              ? t(
                  "requests.addOrUpdateRequest.confirmationModalContentForUpdate",
                  {
                    number: createOrderResponseData
                      ? createOrderResponseData[0]?.Number
                      : "",
                  }
                )
              : t(
                  "requests.addOrUpdateRequest.confirmationModalContentForNew",
                  {
                    number: createOrderResponseData
                      ? createOrderResponseData[0]?.Number
                      : "",
                  }
                )
          }
          btnTitle={t("buttonTexts.okButton")}
          hideClose={true}
        />
      )}
    </>
  );
};
