import { Box, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { theme } from "src/shared/theme/theme";
import { ShadowCard } from "src/components";
import { LinkBankInfoRow } from "../LinkBankInfoRow/LinkBankInfoRow";
import { bankDetailsParams } from "src/shared/models/containers/rent.model";
import { getActivePaymentMethods } from "src/helpers/helperRent";
import { CONSTANT_PAYMENT_METHOD_STATUSES } from "src/shared/utilities/constants";
import { useGetAutoPayQuery } from "src/store/services/privateApi";

interface ILinkedAccountsCard {
  blockTitle?: string;
  linkText?: string;
  bankData?: any;
  emptyBlockContent?: string;
  selectedItemID?: string;
  isPayeeType?: boolean;
  isDefaultTagVisible?: boolean;
  isViewMoreDropDownVisible?: boolean;
  onDeleteAccount?: any;
  onUpdateDefaulAccount?: any;
  onClickBankCard?: any;
  onClickLink?: () => void;
  isCardClickable?: boolean;
  wrapperCardStyle?: {
    cardType?: "default" | "remove_all_shadow";
    marginBottom?: string | number;
    paddingY?: string | number;
  };
  isLastDigits?: boolean;
}

export const LinkedAccountsCard = ({
  blockTitle,
  linkText,
  bankData,
  emptyBlockContent = "Create your first account",
  isPayeeType = false,
  isDefaultTagVisible = true,
  isViewMoreDropDownVisible = true,
  selectedItemID,
  onClickLink,
  onDeleteAccount,
  onUpdateDefaulAccount,
  onClickBankCard,
  isCardClickable,
  wrapperCardStyle = {
    cardType: "default",
    marginBottom: 4,
    paddingY: 4,
  },
  isLastDigits,
}: ILinkedAccountsCard) => {
  const [activePaymentMethods, isActivePaymentMethodsAvailable] =
    getActivePaymentMethods(bankData, false);
  const { data: autoPayData } = useGetAutoPayQuery(null);

  return (
    <ShadowCard
      marginBottom={wrapperCardStyle.marginBottom}
      paddingY={wrapperCardStyle.paddingY}
      className={wrapperCardStyle.cardType}
      id="auto-pay"
    >
      <Typography
        component="p"
        variant="h2Bold"
        textTransform={"uppercase"}
        sx={{
          marginBottom: 3,
          display: "inline-flex",
          alignItems: "center",
          color: "gray.500",
          paddingX: 4,
        }}
      >
        {blockTitle}
      </Typography>
      {isActivePaymentMethodsAvailable &&
        activePaymentMethods.map(
          (bankItem: bankDetailsParams, index: string | number) => {
            return (
              bankItem.status === CONSTANT_PAYMENT_METHOD_STATUSES.ACTIVE && (
                <LinkBankInfoRow
                  bankDetails={bankItem}
                  deleteAccount={onDeleteAccount}
                  key={index}
                  updateDefaultAccount={onUpdateDefaulAccount}
                  isDefaultUpdateOptionAvailable={!isPayeeType}
                  isDefaultTagVisible={isDefaultTagVisible}
                  accountNumberPrefixText={
                    bankItem && bankItem?.type === "BANK"
                      ? "Account"
                      : bankItem.isDebitCard
                      ? "Debit"
                      : "Credit"
                  }
                  isViewMoreDropDownVisible={
                    isViewMoreDropDownVisible && !bankItem?.default
                  }
                  onClickBankCard={onClickBankCard}
                  isSelected={selectedItemID === bankItem.id}
                  isCardClickable={isCardClickable}
                  isLastDigits={isLastDigits}
                  isAutoPayEnabled={!!autoPayData?.data?.isActive}
                />
              )
            );
          }
        )}

      {activePaymentMethods && activePaymentMethods.length === 0 && (
        <Box
          display={"flex"}
          paddingX={4}
          sx={{
            width: "100%",
            marginTop: 2,
            marginBottom: 1,
          }}
        >
          <Typography
            variant="h1"
            component={"div"}
            sx={{
              background: theme.palette.gray[100],
              display: "flex",
              width: "100%",
              padding: 4,
              borderRadius: "4px",
            }}
          >
            {emptyBlockContent}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          paddingY: 4,
          paddingX: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={onClickLink}
      >
        <Typography
          component="span"
          variant="h1Bold"
          color={"primary"}
          textTransform={"capitalize"}
          textAlign={"right"}
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          {linkText}
        </Typography>
        <KeyboardArrowRightIcon color="primary" sx={{ fontSize: "20px" }} />
      </Box>
    </ShadowCard>
  );
};
