import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Header,
  HeaderFixed,
  ManualLoader,
  Wrapper,
  WrpperAuthorizedContainer,
} from "../../components";
import { useNavigate } from "react-router-dom";
import {
  useGetDocsQuery,
  useLazyGetDocQuery,
} from "../../store/services/privateApi";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "@mui/material";

const Docs = () => {
  const [expanded, setExpanded] = useState(false);
  // get the user api details ___
  const { data: docs, isSuccess, isLoading } = useGetDocsQuery(null);
  const [getLazyDocQuery] = useLazyGetDocQuery();
  const [docsData, setDocsData] = useState<Map<string, any[]>>(new Map());
  useEffect(() => {
    const map = new Map<string, any[]>();
    docs?.forEach((doc: any) => {
      const { path } = doc;
      if (["/Signed Lease Documents", "/Lease Documents"].indexOf(path) > -1) {
        let e = map.get(path);
        if (!e) e = [];
        e.push(doc);
        map.set(path, e);
      }
    });
    setDocsData(map);
  }, [docs]);

  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false);
  };
  const navigate = useNavigate();
  return (
    <>
      {isLoading && <ManualLoader />}
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonHide
          onBackButtonClick={() => {
            navigate("/settings");
          }}
          isBackButtonVisible
          paddingX={0}
          headerText={"Documents"}
          withCenteredHeaderText
        />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper idSelector={"wrapperContainer"}>
        <WrpperAuthorizedContainer>
          <List>
            {Array.from(docsData).map(([subCategory, items]) => (
              <ListItem key={subCategory}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{ backgroundColor: "SoftGray" }}
                  >
                    <Typography>{subCategory}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      {items.map((item, index) => (
                        <ListItemText
                          primary={
                            <Link
                              href=""
                              onClick={async (e) => {
                                e.preventDefault();
                                const res: any = await getLazyDocQuery(
                                  item.documentId
                                );
                                if (!res.isError) {
                                  window.open(res.data.uri, "_blank");
                                }
                              }}
                              underline="hover"
                            >
                              {item.name}
                            </Link>
                          }
                        />
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              </ListItem>
            ))}
          </List>
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};

export default Docs;
