import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Header,
  HeaderFixed,
  ManualLoader,
  Wrapper,
  WrpperAuthorizedContainer,
  Modal,
} from "src/components";

import { RequestDetailTop } from "./Details/RequestDetailTop";
import { RequestDetailMiddle } from "./Details/RequestDetailMiddle";
import { RequestDetailBottom } from "./Details/RequestDetailBottom";

import {
  useGetWorkOrderByIdQuery,
  useGetWorkOrderCategoriesQQuery,
} from "src/store/services/privateApi";
import { MaintenanceRequestForm } from "./From/Form";

export const MaintainanceRequestDetailsScreenContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const location = useLocation();
  const [editRequestModalOpen, setEditRequestModalOpen] = useState(false);

  /**
   * Get back button click url from route state
   */
  const { backRouteUrl } =
    location?.state != null
      ? (location?.state as { backRouteUrl: string })
      : {
          backRouteUrl: "/requests",
        };

  /**
   * Render data
   */
  const { data, isLoading, isSuccess, isFetching } = useGetWorkOrderByIdQuery(
    id ?? ""
  );

  const { data: categories, isSuccess: isCategoriesSucccess } =
    useGetWorkOrderCategoriesQQuery({});
  let wo: any;
  if (isSuccess && isCategoriesSucccess) {
    wo = { ...data[0] };
    categories.find((category: any) => {
      return category.subCategories.find((subCategory: any) => {
        if (subCategory.id === wo?.CategoryID) {
          wo.Category = category.name;
          wo.issueType = subCategory.name;
          return true;
        }
      });
    });
  }
  const onClickOpenEditRequestModal = () => {
    setEditRequestModalOpen(true);
  };

  const onClickCloseEditRequestModal = () => {
    setEditRequestModalOpen(false);
  };

  return (
    <>
      {(isLoading || isFetching) && <ManualLoader />}
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonVisible
          paddingX={0}
          headerText={`${t("requests.requestDetail.titlePrefix")} #${
            isSuccess ? data[0]?.Number : ""
          }`}
          withCenteredHeaderText
          onBackButtonClick={() => navigate(backRouteUrl)}
          isEditIconVisible={
            isSuccess && data && data[0]?.Status === "Submitted"
          }
          onClickEditIcon={onClickOpenEditRequestModal}
        />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper idSelector={"wrapperContainer"}>
        <WrpperAuthorizedContainer>
          {isSuccess && (
            <>
              <RequestDetailTop
                t={t}
                data={data[0]}
                onClickEditIcon={onClickOpenEditRequestModal}
              />
              <RequestDetailMiddle t={t} data={wo} />
              <RequestDetailBottom t={t} data={wo} />
            </>
          )}
        </WrpperAuthorizedContainer>
      </Wrapper>
      {editRequestModalOpen && (
        <Modal
          modalTitle={`${t(
            "requests.addOrUpdateRequest.updateRequestTitle"
          )} #${isSuccess ? data[0]?.Number : ""}`}
          onCloseEvent={onClickCloseEditRequestModal}
        >
          {isSuccess && (
            <MaintenanceRequestForm
              t={t}
              navigate={navigate}
              isUpdateRequest
              requestData={wo}
              onSubmitSupportEvent={onClickCloseEditRequestModal}
            />
          )}
        </Modal>
      )}
    </>
  );
};
